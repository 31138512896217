import React from "react"
import { Link } from "gatsby"

export default () => {
  return (
    <ul className="nav nav-pills mb-3" id="pills-tab">
      <li
        className="nav-item"
      >
        <div
            role="button"
            onClick={() => {}}
            onKeyPress={() => {}}
            tabIndex={0}
        >
            <Link className="nav-link" activeClassName="active" to="/faq">
            Top Questions
            </Link>
        </div>
      </li>
      <li
        className="nav-item"
      >
        <div
            role="button"
            onClick={() => {}}
            onKeyPress={() => {}}
            tabIndex={-1} 
        >
            <Link className="nav-link" activeClassName="active" to="/faq/content">
            Content
            </Link>
        </div>
      </li>
      <li
        className="nav-item"
      >
        <div
            role="button"
            onClick={() => {}}
            onKeyPress={() => {}}
            tabIndex={-1}
        >
            <Link className="nav-link" activeClassName="active" to="/faq/returns">
            Returns
            </Link>
        </div>
      </li>
      <li
        className="nav-item"
      >
        <div
            role="button"
            onClick={() => {}}
            onKeyPress={() => {}}
            tabIndex={-1}
        >
            <Link className="nav-link" activeClassName="active" to="/faq/bike">
            Bike
            </Link>
        </div>
      </li>
    </ul>
  )
}
